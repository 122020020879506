<template>
  <router-view></router-view>
</template>
<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import reportStore from "./store.js";

export default {
  name: "report",
  mixins: [RegisterStoreModule],
  created() {
    // console.log("in index");
    this.registerStoreModule("reportView", reportStore);
  },
};
</script>
