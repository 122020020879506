<template>
  <v-container grid-list-lg fluid>
    <div class="headline">Report</div>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <ProfitAndLost />
                <Sale />
                <Inventory />
                <Product />
                <Payment />
                <Purchase />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <Customer />
                <Invoice />
                <Order />
                <Expense />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DateSelector @submit="openReport" />
  </v-container>
</template>

<script>
import Customer from "../components/Customer";
import Product from "../components/Product";
import Invoice from "../components/Invoice";
import Payment from "../components/Payment";
import Order from "../components/Order";
import Expense from "../components/Expense";
import Purchase from "../components/Purchase";
import ProfitAndLost from "../components/ProfitAndLost";
import Sale from "../components/Sale";
import Inventory from "../components/Inventory";
import DateSelector from "@/modules/Report/components/DateSelector";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Customer,
    Inventory,
    Sale,
    Product,
    Invoice,
    Expense,
    ProfitAndLost,
    Purchase,
    Payment,
    Order,
    DateSelector,
  },
  computed: {
    ...mapGetters("reportView", ["getReportName"]),
     ...mapGetters("global", [
      "currentBusiness",
    ]),
  },
  methods: {
    ...mapActions("reportView", ["setReportName"]),
    openReport(val) {
      // console.log("openReport", val, this.getReportName);
      let routeData = this.$router.resolve({
        path: `/reportView/${this.getReportName}?start=${val.start}&end=${val.end}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped></style>
