c<template>
  <v-list subheader>
    <v-subheader>Customer</v-subheader>
    <v-list-item @click="openReport('list')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/buyer.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Customer List</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openReport('balanceSheet')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/balance.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Balance Sheet</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openReport('noInvoice')"> 
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/shop.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          >Customer didn't order last 60 days</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item>
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/refund.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Refund By Customer</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item>
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/passdueBill.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Customer Invoice Passed Due</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  methods: {
    openReport(link) {
      let routeData = this.$router.resolve({
        path: `/reportView/customer/${link}`
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped></style>
