<template>
  <v-list subheader>
    <v-subheader>Invoice</v-subheader>

    <v-list-item @click="openReport('due')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/tax.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Due Invoice</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openDateSelector('invoice/dueByDate')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/tax.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Due Invoice By Date</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openDateSelector('invoice/refundInvoice')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/refund.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Refund Invoice By Date</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider inset></v-divider>
    <v-list-item>
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/passdueBill.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Passed Due Invoice</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("reportView", ["setDateSelector", "setReportName"]),
    openReport(link) {
      let routeData = this.$router.resolve({
        path: `/reportView/invoice/${link}`,
      });
      window.open(routeData.href, "_blank");
    },
    openDateSelector(val) {
      this.setReportName(val);
      this.setDateSelector(true);      
    },
  },
};
</script>

<style scoped></style>
