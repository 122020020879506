<template>
  <v-list subheader>
    <v-subheader>Order</v-subheader>

    <v-list-item @click="openDateSelector('order/byDate')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/accuracy.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Order By Date</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider inset></v-divider>
    <v-list-item>
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/buyer.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Order By Customer</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <!-- <v-divider inset></v-divider> -->
  </v-list>
</template>

<script>
import { mapActions } from 'vuex';
export default {
   methods: {
    ...mapActions("reportView", ["setDateSelector", "setReportName"]),
    openReport(link) {
      let routeData = this.$router.resolve({
        path: `/reportView/invoice/${link}`,
      });
      window.open(routeData.href, "_blank");
    },
    openDateSelector(val) {
      this.setReportName(val);
      this.setDateSelector(true);      
    },
  },
};
</script>

<style scoped></style>
