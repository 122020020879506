<template>
  <div>
    <v-list subheader>
      <v-subheader>Inventory</v-subheader>

      <v-list-item @click="openReportWithDate('serial')">
        <v-list-item-avatar tile>
          <v-img src="@/assets/Report/barcode.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Serial Balance By Date</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider inset></v-divider>
      <v-list-item @click="openReport('availableSerial')">
        <v-list-item-avatar tile>
          <v-img src="@/assets/Report/buyer.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Available Serial</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider inset></v-divider>
      <v-list-item @click="openReport('inventoryValue')">
        <v-list-item-avatar tile>
          <v-img src="@/assets/Report/deficit.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Inventory Valuation</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="custom" width="450">
      <v-card>
        <ModelTitle title="Custom Date" @close="cancel()" />

        <!-- <CardToolbar :title="$t('pageHeader.customDate')" :isModal="true" @cancel="cancel" /> -->
        <v-container class="pa-5" grid-list-lg>
          <v-layout row wrap justify-center align-center>
            <!-- START -->
            <v-flex shrink>
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="false"
                :return-value.sync="startDateDisplay"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-on="on"
                    dense
                    hide-details
                    v-model="startDateDisplayNormal"
                    :label="`${range ? 'Start Date' : label ? label : 'Date'}`"
                    readonly
                    :clearable="clearable"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="startDate"
                  scrollable
                  v-show="!start.selectTime"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click.stop.prevent="startMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click.stop.prevent="$refs.startMenu.save(startDateDisplay)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info" :disabled="!startDateDisplayNormal"
            class="v-btn-with-radius"
            @click.stop.prevent="submit"
          >
            {{ $t("buttons.go") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      selectedDate: null,
      hideTime: false,
      range: false,
      clearable: true,
      label: "Date",
      startMenu: false,
      start: {
        date: null,
      },
      custom: false,
      link: null,
    };
  },
  computed: {
    startDateDisplayNormal: {
      get() {
        return this.start.date
          ? `${this.start.date.format("MM/DD/YYYY")} ${
              this.start.time
                ? this.$moment(
                    `${this.start.date.format("YYYY-MM-DD")} ${this.start.time}`
                  ).format("hh:mm a")
                : ""
            }`
          : null;
      },
      set() {
        this.start.time = null;
        this.start.date = null;
      },
    },
    startDate: {
      get() {
        return this.start.date
          ? `${this.start.date.format("YYYY-MM-DD")}`
          : null;
      },
      set(val) {
        if (!val) return (this.start.date = null);
        this.start.date = new this.$moment(val);

        if (this.start.time) {
          const time = this.start.time.split(":");
          this.start.date.set({ hour: time[0], minute: time[1] });
        }
      },
    },
    startDateDisplay: {
      get() {
        return this.start.date
          ? `${this.start.date.format("MM/DD/YYYY")} ${
              this.start.time ? this.start.time : ""
            }`
          : null;
      },
      set(val) {
        if (val) {
          const newDate = new Date(val).toISOString();
          this.startDate = this.$moment(newDate).format("YYYY-MM-DD");
        } else {
          this.startDate = null;
          this.start.time = null;
        }
      },
    },
  },
  methods: {
    ...mapActions("reportView", ["setDateSelector", "setReportName"]),
    cancel() {
      this.custom = false;
    },
    submit() {
      let date = null;
      if (this.start.date) {
        date = this.start.time
          ? this.start.date.toISOString()
          : this.start.date.startOf("day").toISOString();
      }
      this.selectedDate = date;
      let routeData = this.$router.resolve({
        path: `/reportView/inventory/${this.link}?date=${this.selectedDate}`,
      });
      window.open(routeData.href, "_blank");
    },
    openReportWithDate(link) {
      // console.log('link', link)
      this.custom = true;
      this.link = link;
    },
    openReport(link) {
      let routeData = this.$router.resolve({
        path: `/reportView/inventory/${link}`,
      });
      window.open(routeData.href, "_blank");
    },
    openDateSelector(val) {
      this.setReportName(val);
      this.setDateSelector(true);
    },
  },
};
</script>

<style scoped></style>
