<template>
  <v-list subheader>
    <v-subheader>Expense</v-subheader>

    <v-list-item @click="openDateSelector('expense/list')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/accuracy.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Expense By Date</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openDateSelector('expense/byPayee')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/pay.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Expense By Payee</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item @click="openReport('payee/list')">
      <v-list-item-avatar tile>
        <v-img src="@/assets/Report/payperson.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Payee List</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("reportView", ["setDateSelector", "setReportName"]),
    openReport(link) {
      let routeData = this.$router.resolve({
        path: `/reportView/expense/${link}`,
      });
      window.open(routeData.href, "_blank");
    },
    openDateSelector(val) {
      this.setReportName(val);
      this.setDateSelector(true);      
    },
  },
};
</script>

<style scoped></style>
